import React from 'react'

import PageWrapper from '../components/PageWrapper'
import Hero from '../sections/home/Hero'

const IndexPage = () => {
  return (
    <PageWrapper
      footerConfig={{
        style: 'style1',
      }}
    >
      <Hero />
    </PageWrapper>
  )
}
export default IndexPage
